<template>
    <seccion-datos 
        :titulo="opciones" 
        @cambios="guardarDatosOpciones($data)" 
        tipo="success"
        >
        <div class="row"> 
            <div class="col-md-6">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input 
                            class="custom-control-input custom-control-input-danger" 
                            type="checkbox" 
                            id="finaliza-operario-visita" 
                            v-model="opcion_finaliza_operario_companyia"
                            v-bind:true-value="1" 
                            v-bind:false-value="0"
                        >
                        <label for="finaliza-operario-visita" class="custom-control-label">{{ $t('general.opcionfinalizaroperariovisita') }}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                            class="custom-control-input" 
                            type="checkbox" 
                            id="fecha-espera-obligatoria" 
                            v-model="fecha_espera_obligatoria"
                            v-bind:true-value="1" 
                            v-bind:false-value="0"
                        >
                        <label for="fecha-espera-obligatoria" class="custom-control-label">{{ $t('general.fechadeesperaobligatoria') }}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                            class="custom-control-input" 
                            type="checkbox" 
                            id="servicio-cia-tramitado" 
                            v-model="servicio_compania_tramitado"
                            v-bind:true-value="1" 
                            v-bind:false-value="0"
                        >
                        <label for="servicio-cia-tramitado" class="custom-control-label">{{ $t('general.serviciodeusuariocompaniatramitado') }}</label>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input 
                        class="custom-control-input custom-control-input-danger" 
                        type="checkbox" 
                        id="asignar-correduria" 
                        v-model="asignar_correduria"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                    >
                    <label for="asignar-correduria" class="custom-control-label">{{ $t('general.asignaracorreduriaadmfincas') }}</label>
                </div>
                <div class="custom-control custom-checkbox">
                    <input 
                        class="custom-control-input custom-control-input-warning " 
                        type="checkbox" 
                        id="notificar-comunicaciones-sms" 
                        v-model="notificar_comunicaciones_sms"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                    >
                    <label for="notificar-comunicaciones-sms" class="custom-control-label">{{ $t('general.notificarenviossms') }}</label>
                </div>
                <div class="custom-control custom-checkbox">
                    <input 
                        class="custom-control-input custom-control-input-danger" 
                        type="checkbox" 
                        id="auto-numerico" 
                        v-model="autonumeracion_companyia"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                    >
                    <label for="auto-numerico" class="custom-control-label">{{ $t('general.autonumerico') }}</label>
                </div>   
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="email-fotos-cia">{{ $t('general.emailfotos') }}</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        id="email-fotos-cia" 
                        placeholder="Email para envío de fotos" 
                        v-model="email_envio_fotos_companyia"
                    >
                </div>
                
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{ $t('general.rentabilidad') }}</label>
                    <div class="input-group">
                        <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                        <div class="input-group-append">
                            <span class="input-group-text form-control-sm">%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label for="max-expedientes-relacionados-poliza">{{ $t('general.relacionadosporpoliza') }}</label>
                    <input 
                        type="number" 
                        class="form-control form-control-sm" 
                        id="max-expedientes-relacionados-poliza" 
                        placeholder="" 
                        v-model="max_expedientes_relacionados_poliza"
                    >
                </div>
            </div>
        </div>
        
        
        <!-- Inicio Recuperación activos -->
        <div class="card card-danger card-outline">                            
            <div class="card-body ">
                <h5>{{ $t('general.serviciosderecuperaciondeativos') }}</h5>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-7">
                            <label for="limite-maximo-realizacion-trabajos">{{ $t('general.limitemaximoderealizaciondelostrabajos') }}</label>
                        </div>
                        <div class="col-md-5">
                            <div class="input-group">
                                <input 
                                    type="number" 
                                    class="form-control form-control-sm" 
                                    id="limite-maximo-realizacion-trabajos" 
                                    placeholder="" 
                                    v-model="recuperacion_activos_dias_plazo">
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">{{ $t('general.diaslaborables') }}</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>                                                                        
                </div>
            </div>
        </div>
        <!-- Fin Recuperación activos -->

        <!-- Inicio RGA -->
        <!-- Si no tiene habilitado RGA, ocultar -->
        <div class="card card-warning card-outline">                            
            <div class="card-body ">
                <h5>RGA</h5>
                <div class="custom-control custom-checkbox">
                    <input 
                        class="custom-control-input custom-control-input-danger" 
                        type="checkbox" 
                        id="autogestionar-facturacion" 
                        v-model="autogestionar_facturacion"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                    >
                    <label for="autogestionar-facturacion" class="custom-control-label">{{ $t('general.autogestionarfacturacion') }}</label>
                </div> 
            </div>
        </div>
        <!-- Fin RGA -->

        <!-- Inicio Servicios puntuales -->
        <div class="card card-primary card-outline">   
            <div class="card-header">
                <h3 class="card-title">{{ $t('general.serviciospuntuales') }}</h3>
                <div class="card-tools">
                    <div class="custom-control custom-switch ">
                        <!-- Si desactivado, ocultar "campos servicios puntuales" -->
                        <input 
                            type="checkbox" 
                            class="custom-control-input" 
                            id="servicios-puntuales" 
                            :checked="serviciosPuntuales"
                            @change="cambioServiciosPuntuales"
                            ref="apartadoServiciosPuntuales">
                        <label class="custom-control-label" for="servicios-puntuales"></label>
                    </div>
                </div> 
            </div>   
            <!-- Inicio campos servicios puntuales -->                     
            <div class="card-body ">
                <div class="row" v-if="serviciosPuntuales">
                    <div class="col-md-4">
                        <div class="input-group">
                            <label for="importe-cliente-puntual">{{ $t('general.importeclientepuntual') }}</label>
                            <div class="input-group">                                                
                                <input 
                                    type="number" 
                                    class="form-control form-control-sm" 
                                    id="importe-cliente-puntual" 
                                    placeholder="" 
                                    v-model="importe_puntual_companyia"
                                >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">&euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group">
                            <label for="valor-negociado-cia">{{ $t('general.valornegociado') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <input type="checkbox">
                                    </span>
                                </div>
                                <input 
                                    type="number" 
                                    class="form-control form-control-sm" 
                                    id="valor-negociado-cia" 
                                    placeholder="" 
                                    v-model="saldo_negociado_companyia"
                                >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">&euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group">
                            <label for="importe-operario-puntual">{{ $t('general.importeoperariopuntual') }}</label>
                            <div class="input-group">                                                
                                <input 
                                    type="number" 
                                    class="form-control form-control-sm" 
                                    id="importe-operario-puntual" 
                                    placeholder="" 
                                    v-model="importe_operario_puntual_companyia"
                                >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">&euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- Fin campos servicios puntuales -->
        </div>
        <!-- Fin puntuales -->
    </seccion-datos>
</template>

<script>
export default {
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return  {
            opcion_finaliza_operario_companyia: null,
            asignar_correduria: null,
            fecha_espera_obligatoria: null,
            notificar_comunicaciones_sms: null,
            servicio_compania_tramitado: null,
            autonumeracion_companyia: null,
            email_envio_fotos_companyia: null,
            rentabilidad: null,
            max_expedientes_relacionados_poliza: null,
            recuperacion_activos_dias_plazo: null,
            autogestionar_facturacion: null,
            importe_puntual_companyia: null,
            importe_operario_puntual_companyia: null,
            saldo_negociado_companyia: null,
            max_expedientes_relacionados_nif: null,
            asistencias: null,
            opciones:"Opciones",
            serviciosPuntuales: false,
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            this.serviciosPuntuales = !!+this.importe_puntual_companyia 
                                        || 
                                    !!+this.saldo_negociado_companyia
                                        ||
                                    !!+this.importe_puntual_companyia; 
                                    
            this.opciones = this.$t('general.opciones');

        },
        cambioServiciosPuntuales(e) {
            this.serviciosPuntuales = e.currentTarget.checked;
        },
        /**
         * Necesitamos este método para quitar de la data la propiedad que no queremos enviar al servidor, y que produciría error
         */
        guardarDatosOpciones(data) {
            const datosGuardar = {...data};
            delete datosGuardar.serviciosPuntuales;
            this.guardar(datosGuardar);
        }
    },
    created() {
        this.init();
    }
}
</script>